import {
  ENOTARY_CAN_ACCESS,
  ENOTARY_CREATE_MEETING,
  ENOTARY_GO_TO_HOME,
  ENOTARY_JOIN_MEETING,
  ENOTARY_MEETING_CREATED,
  ENOTARY_MEETING_JOINED, 
  RESET_VERIFICATION_RESULT,
  SAVE_INQUIRY_FORM,
  SEND_OTP,
  VERIFICATION_RESULT,
  VERIFY_OTP,
  ENOTARY_MEETING_LEFT,
} from "../actions/enotaryTypes";

const initialState = {};

export const enotary = (state = initialState, action) => {
  switch (action.type) {
    case ENOTARY_CREATE_MEETING:
      return {
        ...state,
        payload: {
          ...state.payload,
          ...action.payload,
        },
        type: ENOTARY_CREATE_MEETING,
      };
    case SAVE_INQUIRY_FORM:
      return {
        ...state,
        payload: {
          ...state.payload,
          ...action.payload,
        },
        type: SAVE_INQUIRY_FORM,
      };
    case ENOTARY_MEETING_CREATED:
      return {
        ...state,
        payload: {
          ...state.payload,
          ...action.payload,
        },
        type: ENOTARY_MEETING_CREATED,
      };
    case ENOTARY_GO_TO_HOME:
      return {
        ...state,
        payload: {
          ...state.payload,
          ...action.payload,
        },
        type: ENOTARY_GO_TO_HOME,
      };
    case ENOTARY_JOIN_MEETING:
      return {
        ...state,
        payload: {
          ...state.payload,
          ...action.payload,
        },
        type: ENOTARY_JOIN_MEETING,
      };
    case SEND_OTP:
      return {
        ...state,
        payload: {
          ...state.payload,
          ...action.payload,
        },
        type: SEND_OTP,
      };
    case VERIFY_OTP:
      return {
        ...state,
        payload: {
          ...state.payload,
          ...action.payload,
        },
        type: VERIFY_OTP,
      };
    case VERIFICATION_RESULT:
      return {
        ...state,
        payload: {
          ...state.payload,
          ...action.payload,
        },
        type: VERIFICATION_RESULT,
      };
    case RESET_VERIFICATION_RESULT:
      return {
        ...state,
        payload: {
          ...state.payload,
          ...action.payload,
        },
        type: RESET_VERIFICATION_RESULT,
      };
    case ENOTARY_MEETING_JOINED:
      return {
        ...state,
        payload: {
          ...state.payload,
          ...action.payload,
        },
        type: ENOTARY_MEETING_JOINED,
      };
    case ENOTARY_CAN_ACCESS:
      return {
        ...state,
        payload: {
          ...state.payload,
          ...action.payload,
        },
        type: ENOTARY_CAN_ACCESS,
      };
    case ENOTARY_MEETING_LEFT:
      return {
        ...state,
        payload: {
          ...state.payload,
          ...action.payload,
        },
        type: ENOTARY_MEETING_LEFT,
      };
    default:
      return state;
  }
};
