import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  TextField,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
  resetVerificationResult,
  verifyOtp,
} from "../../../store/actions/enotary";
import { useDispatch, useSelector } from "react-redux";
import Alert from "@material-ui/lab/Alert";

const Verify = ({ open, handleClose, data = {} }) => {
  const dispatch = useDispatch();
  const [otp, setOtp] = useState("");
  const enotary = useSelector((state) => state.enotary);

  const onSubmit = async () => {
    dispatch(verifyOtp({ ...data, otp }));
  };

  const localHandleClose = () => {
    dispatch(
      resetVerificationResult({
        ...enotary.payload,
        verified: null,
      })
    );
    setOtp("");
    handleClose();
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (enotary?.payload?.verified?.flag) {
        localHandleClose();
      }
    }, 2000);
    return () => {
      clearTimeout(timeout);
    };
  }, [enotary?.payload?.verified]);

  return (
    <Dialog
      onClose={localHandleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <div
        style={{
          padding: "18px",
          display: "flex",
          flexDirection: "column",
          gap: 12,
        }}
      >
        <DialogTitle
          id="simple-dialog-title"
          style={{
            padding: 0,
          }}
        >
          Verify User: {data.name}
        </DialogTitle>
        <Divider />
        <Alert color={"info"}>OTP has been sent</Alert>
        <TextField
          name="otp"
          variant="outlined"
          label="OTP"
          type="number"
          fullWidth
          required
          value={otp}
          onChange={(e) => setOtp(e.target.value?.slice(0, 6))}
        />
        {enotary?.payload?.verified?.message && (
          <Alert color={enotary?.payload?.verified?.flag ? "success" : "error"}>
            {enotary?.payload?.verified?.message}
          </Alert>
        )}
        {!enotary?.payload?.verified?.flag && (
          <>
            <Divider />
            <DialogActions>
              <Button onClick={localHandleClose}>Close</Button>
              <Button
                variant="contained"
                color="primary"
                onClick={onSubmit}
                disabled={otp?.length !== 6 || enotary?.payload?.verified?.flag}
              >
                Verify
              </Button>
            </DialogActions>
          </>
        )}
      </div>
    </Dialog>
  );
};

export default Verify;
