import {
  Avatar,
  Box,
  Button,
  Divider,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { color } from "../../../assets/styles/_color";
import MicNoneOutlinedIcon from "@material-ui/icons/MicNoneOutlined";
import MicOffOutlinedIcon from "@material-ui/icons/MicOffOutlined";
import MoreVertOutlinedIcon from "@material-ui/icons/MoreVertOutlined";
import { useDispatch, useSelector } from "react-redux";
import SearchBox from "../SearchBox";
import classnames from "classnames";
import CopyMeetingLink from "../CopyMeetingLink";
import { MenuBox } from "../MenuBox";
import { setPinParticipant } from "../../../store/actions/layout";
import {
  getModerator,
  getParticipants,
  isParticipantTrackMuted,
} from "../../../utils";
import Verify from "../Verify";
import { setOtp } from "../../../store/actions/enotary";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "95%",
    [theme.breakpoints.down("md")]: {
      height: "82%",
    },
  },
  underRoot: {
    [theme.breakpoints.down("md")]: {
      height: "100%",
    },
  },
  title: {
    color: color.secondary,
    fontSize: "0.85rem",
  },
  localBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(2, 0),
    color: color.white,
  },
  userBoxContainer: {
    display: "flex",
    flexDirection: "column",
    "& span": {
      marginLeft: "10px",
    },
  },
  userBox: {
    display: "flex",
    alignItems: "center",
    "&>div.MuiAvatar-root": {
      width: "30px",
      height: "30px",
      fontSize: "1rem",
    },
    "& p": {
      fontSize: "0.9rem",
    },
  },
  hostDetails: {
    display: "flex",
    flexDirection: "row",
  },
  hostBox: {
    display: "inline-flex",
    width: "auto !important",
    marginLeft: "10px",
    height: "23px !important",
  },
  iconBox: {
    display: "flex",
    alignItems: "center",
    "&>svg": {
      color: color.white,
      fontSize: "1.35rem",
    },
  },
  more: {
    marginLeft: theme.spacing(1),
    "&:hover": {
      color: color.primaryLight,
      cursor: "pointer",
    },
  },
  divider: {
    backgroundColor: color.search,
    marginTop: "auto",
    marginBottom: theme.spacing(2),
  },
  meetingDetailsContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    color: color.white,
  },
  meetingDetails: {
    marginLeft: theme.spacing(1),
    color: color.white,
    "& p": {
      color: color.primaryLight,
    },
    "& span": {
      fontWeight: 300,
    },
  },
  pin: {
    color: color.primary,
  },
  iconContainer: {
    padding: "8px 12px 4px 12px",
    border: `1px solid transparent`,
    background: color.secondary,
    borderRadius: "48px",
    "&:hover": {
      opacity: "0.8",
      cursor: "pointer",
      border: `1px solid ${color.primaryLight}`,
      background: color.secondaryDark,
    },
  },
  verifyBox: {
    padding: "0px 12px",
  },
  verifyButton: {
    color: "white",
    fontSize: 12,
    textDecoration: "underline",
    cursor: "pointer",
  },
  verifiedButton: {
    color: "white",
    fontSize: 12,
    color: "green",
  },
}));

const ParticipantDetails = () => {
  const classes = useStyles();
  const conference = useSelector((state) => state.conference);
  const enotary = useSelector((state) => state.enotary);
  const remoteTracks = useSelector((state) => state.remoteTrack);
  const localTracks = useSelector((state) => state.localTrack);
  const profile = useSelector((state) => state.profile);
  const localUser = conference.getLocalUser();
  const [participantName, setParticipantName] = useState("");
  const [selectedOption, setSelectedOption] = useState({});
  const [verifyModal, setVerifyModal] = useState({ open: false });

  const handleClose = () => {
    setVerifyModal({ open: false });
  };

  const handleOpen = (data) => {
    dispatch(setOtp(data));
    setVerifyModal({ open: true, data });
  };

  const handleMenuOpen = (event, item) => {
    setSelectedOption({ ...selectedOption, [item]: event.currentTarget });
  };

  const handleMenuClose = (item) => {
    setSelectedOption({ ...selectedOption, [item]: null });
  };

  const handleOptionClick = (option, item) => {
    handleMenuClose(item);
  };

  const [participants, setParticipants] = useState(
    getParticipants(conference, enotary?.payload)
  );

  const dispatch = useDispatch();

  const handleParticipantNameChange = (e) => {
    setParticipantName(e.target.value);
  };

  useEffect(() => {
    setParticipants(getParticipants(conference, enotary?.payload));
  }, [
    conference.getParticipantsWithoutHidden()?.length,
    JSON.stringify(enotary?.payload?.verified),
  ]);

  let filteredParticipants = !participantName
    ? participants
    : participants.filter((participant) =>
        participant?._identity?.user?.name
          ?.toLowerCase()
          .includes(participantName.toLowerCase())
      );

  filteredParticipants = filteredParticipants.filter((participant) =>
    !participant?._identity?.user?.name
      ?.toLowerCase()
      .includes("recorder")
  );

  const togglePinParticipant = (id) => {
    dispatch(setPinParticipant(id));
  };

  const getAvatarColor = (id) => {
    return (
      conference.participants.get(id)?._identity?.user?.avatar || profile?.color
    );
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.underRoot}>
        <SearchBox
          placeholder={"Search Participants"}
          value={participantName}
          id="participantName"
          name="participantName"
          handleChange={handleParticipantNameChange}
        />
        <Verify
          open={verifyModal.open}
          data={verifyModal.data}
          handleClose={handleClose}
        />
        <Box
          sx={{
            maxHeight: `calc(100% - 70px)`,
            overflow: "auto",
            height: "100%",
          }}
        >
          {filteredParticipants.map(
            (participant, p) => (
              <Box>
                {console.log("=>", participant)}
                <Box className={classes.localBox}>
                  <Box className={classes.userBox}>
                    <Avatar
                      style={{
                        backgroundColor: getAvatarColor(participant?._id),
                      }}
                    >
                      {participant?._identity?.user?.name
                        .toUpperCase()
                        .slice(0, 1)}
                    </Avatar>
                    <Box className={classes.userBoxContainer}>
                      <Box className={classes.hostDetails}>
                        <Box className={classes.hostBox}>
                          <Typography>
                            {participant?._identity?.user?.name}{" "}
                            {localUser.id === participant?._identity?.user?.id
                              ? "(You)"
                              : null}
                            {getModerator(conference)?._id === participant?._id ? " (Meeting Host)" : null}
                          </Typography>
                        </Box>
                      </Box>
                      {/* {enotary?.payload?.inquiryData?.isNotaryUser &&
                        getModerator(conference)?._id !== participant?._id &&
                        (participant?._identity?.user?.hasVerified ? (
                          <Box className={classes.verifyBox}>
                            <div className={classes.verifiedButton}>
                              Verified
                            </div>
                          </Box>
                        ) : (
                          <Box className={classes.verifyBox}>
                            <div
                              className={classes.verifyButton}
                              variant="primary"
                              onClick={() =>
                                handleOpen({
                                  id: participant?._id,
                                  role:
                                    participant?._role || conference.getRole(),
                                  name: participant?._identity?.user?.name,
                                })
                              }
                            >
                              Verify user
                            </div>
                          </Box>
                        ))} */}
                    </Box>
                  </Box>
                  <Box className={classes.iconBox}>
                    {isParticipantTrackMuted(
                      localTracks,
                      remoteTracks,
                      participant?._id,
                      conference,
                      "audio"
                    ) ? (
                      <MicOffOutlinedIcon />
                    ) : (
                      <MicNoneOutlinedIcon />
                    )}
                    <Tooltip title="More Actions">
                      <MoreVertOutlinedIcon
                        className={classes.more}
                        onClick={(e) => handleMenuOpen(e, participant._id)}
                      />
                    </Tooltip>
                    <MenuBox
                      anchorEl={selectedOption[participant._id]}
                      selectedOption={selectedOption}
                      open={Boolean(selectedOption[participant._id])}
                      optionParams={{
                        id: participant._id,
                        role: participant?._role || conference.getRole(),
                      }}
                      togglePinParticipant={togglePinParticipant}
                      handleOptionClick={handleOptionClick}
                      handleMenuClose={handleMenuClose}
                    />
                  </Box>
                </Box>
              </Box>
            )
            // )
          )}
        </Box>
      </Box>
      <Box style={{ display: "none" }}>
        <Divider className={classes.divider} />
        <Box className={classes.meetingDetailsContainer}>
          <Box className={classes.meetingDetails}>
            <Typography>Meeting Link</Typography>
            <Typography variant="caption">{window.location.href}</Typography>
          </Box>
          <CopyMeetingLink textToCopy={window.location.href} />
        </Box>
      </Box>
    </Box>
  );
};

export default ParticipantDetails;
