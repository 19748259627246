import {
  createEnotaryMeeting,
  joinEnotaryMeeting, 
  canAccessENotary,
  saveInquiryData,
  verificationResult,
  enotaryMeetingLeft,
} from "./store/actions/enotary";
import SariskaMediaTransport from "sariska-media-transport";
import { store } from "./store";
import { 
  ENOTARY_GO_TO_HOME,
  SEND_OTP,
  VERIFY_OTP,
  ENOTARY_MEETING_LEFT,
} from "./store/actions/enotaryTypes";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef } from "react";
import { s3 } from "./constants";
import { showSnackbar } from "./store/actions/snackbar";
import { updateProfile } from "./store/actions/profile";
import { useHistory } from "react-router-dom";
import { clearAllReducers } from "./store/actions/conference";

const prefix = "notary_meeting_";
let unsubscribe;
let amICreator = false;
let recordingRequested = false;
let isPublicUser = true;
let generateTokenCallback;


const subscribeStore = () => {
  unsubscribe && unsubscribe();
  // Subscribe to store updates
  unsubscribe = store.subscribe(() => {
    const state = store.getState();
    if (state?.enotary?.type === ENOTARY_GO_TO_HOME) { 
      window.top.postMessage( 
        prefix + JSON.stringify({ type: "GO_TO_HOME" }),
        "*"
      );
    } else if (state?.enotary?.type === SEND_OTP) {
      window.top.postMessage(
        prefix +
          JSON.stringify({ type: "SEND_OTP", data: state?.enotary?.payload }),
        "*"
      );
    } else if (state?.enotary?.type === VERIFY_OTP) {
      window.top.postMessage(
        prefix +
          JSON.stringify({ type: "VERIFY_OTP", data: state?.enotary?.payload }),
        "*"
      );
    } else if (state?.enotary?.type === ENOTARY_MEETING_LEFT) {
      window.top.postMessage(
        prefix + JSON.stringify({ type: "MEETING_LEFT" }),
        "*"
      );
    }
});
};

const ENotaryCommunicator = () => {
  const eNotaryBaseUrl = process.env.REACT_APP_ENOTARY_APP_BASE_URL;
  const recordingSession = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    // Code to run once on mount
    window.addEventListener("message", (event) => {
      const value = event.data;
      if (value && typeof value === "string" && value.startsWith(prefix)) {
        if (event.origin !== eNotaryBaseUrl) {
          console.error("Event origin must be " + eNotaryBaseUrl);
          return;
        }
  
        const dataStr = value.replace(prefix, "");
        const parsedData = JSON.parse(dataStr);
    
        if (parsedData.type === "CREATE_MEETING") {
          amICreator = true;
          isPublicUser = false;
          subscribeStore();
          store.dispatch(updateProfile({ key: "id", value: parsedData.data.userId }));
          store.dispatch(updateProfile({ key: "email", value: parsedData.data.email }));
          store.dispatch(updateProfile({ key: "name", value: parsedData.data.name }));
          store.dispatch(updateProfile({ key: "moderator", value: true }));

          store.dispatch(canAccessENotary(parsedData.data)); 
          store.dispatch(createEnotaryMeeting(parsedData.data));
        } 
  
        if (parsedData.type === "JOIN_MEETING") {
          subscribeStore();
          isPublicUser = false;
          store.dispatch(updateProfile({ key: "id", value: parsedData.data.userId }));
          store.dispatch(updateProfile({ key: "email", value: parsedData.data.email }));
          store.dispatch(updateProfile({ key: "name", value: parsedData.data.name }));
          store.dispatch(updateProfile({ key: "moderator", value: false }));

          store.dispatch(joinEnotaryMeeting(parsedData.data));
        }
  
        if (parsedData.type === "OTP_VERIFICATION_RESULT") {
          subscribeStore();
          store.dispatch(verificationResult(parsedData.data)); 
        }

        if (parsedData.type === "MEETING_TIMEOUT") {
          endMeeting();
        }

        if (parsedData.type === "GENERATE_TOKEN_RESULT") {
          generateTokenCallback(parsedData.data);
        }
      }
    });
  
    // Inform parent that i'm loaded
    window.top.postMessage(prefix + JSON.stringify({ type: "LOADED" }), "*");

    
  }, []); // Empty dependency array ensures this runs only once

  const startRecording = async () => {
    if(!amICreator || recordingRequested) {
      return;
    }

    recordingRequested = true;
    dispatch(
      showSnackbar({
        severity: "info",
        message: "Starting Recording",
        autoHide: true,
      })
    );

    const session = await conference.startRecording({
      mode: SariskaMediaTransport.constants.recording.mode.FILE,
      appData: JSON.stringify(s3),
    });
    recordingSession.current = session;
  };

  const userJoined = (id, participant) => {
    if(!amICreator) {
      return;
    }

    window.top.postMessage(
      prefix +
        JSON.stringify({ type: "USER_JOINED", data: participant.getIdentity() }),
      "*"
    );

    startRecording();
  };

  const userLeft = (id, participant) => {
    if(!amICreator) {
      return;
    }

    window.top.postMessage(
      prefix +
        JSON.stringify({ type: "USER_LEFT", data: participant.getIdentity() }),
      "*"
    );
  };

  const endMeeting = () => {
    conference
      ?.getParticipantsWithoutHidden()
        .map((p) => p?._id)
        .forEach(async (id) => await conference.kickParticipant(id));
      dispatch(clearAllReducers());
      dispatch(enotaryMeetingLeft());
      history.push("/leave");
  };

  const userRoleChanged = (id, role) => {
    if(role === "moderator" && id && conference.myUserId() == id && !amICreator) {
      endMeeting();
    }
  };

  const conference = useSelector(state => state.conference);
  useEffect(() => {
    if (!conference) {
      return;
    }

    conference.addEventListener(SariskaMediaTransport.events.conference.USER_JOINED, userJoined);
    conference.addEventListener(SariskaMediaTransport.events.conference.USER_LEFT, userLeft);
    conference.addEventListener(SariskaMediaTransport.events.conference.USER_ROLE_CHANGED, userRoleChanged);

    return () => {
      conference.removeEventListener(SariskaMediaTransport.events.conference.USER_JOINED, userJoined);
      conference.removeEventListener(SariskaMediaTransport.events.conference.USER_LEFT, userLeft);
      conference.removeEventListener(SariskaMediaTransport.events.conference.USER_ROLE_CHANGED, userRoleChanged);

    };
  }, [conference]);



  return <></>;
};

const generateLoginUserToken = (profile, name, avatarColor) => {
  const promise = new Promise((resolve, reject) => {
    generateTokenCallback = (data) => {
      if(data.success) {
        resolve(data.token)
      } else {
        reject();
      }
      generateTokenCallback = null;
    };

    directCommunicatorEvent({
      type: "GENERATE_TOKEN",
      data: {
        profile, name, avatarColor
      },
    });
  });
  return promise;
};

const generateRecorderToken = async (profile, name, avatarColor) => {
  if(name !== "recorder") {
    console.error('Name must be recorder');
    return;
  }

  try {
    let uuid = window.location.pathname.split('/')[1];
    const response = await fetch(`${process.env.REACT_APP_ENOTARY_API_URL}/notary-document-schedule/generate-token/recorder/${uuid}/${profile.id}`);
    if (response.ok) {
      const json = await response.json();
      localStorage.setItem("SARISKA_TOKEN", json.token);
      return json.token;
    } else {
      console.log(response.status);
    }
  } catch (error) {
    console.log("error", error);
  }
}

export const generateToken = (profile, name, avatarColor) => {
  if(!isPublicUser) {
    return generateLoginUserToken(profile, name, avatarColor);
  }
  return generateRecorderToken(profile, name, avatarColor);
};

export const directCommunicatorEvent = (payload) => {
  window.top.postMessage(
    prefix +
      JSON.stringify(payload),
    "*"
  );
}

export default ENotaryCommunicator;
