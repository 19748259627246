export const ENOTARY_CREATE_MEETING = "ENOTARY_CREATE_MEETING";
export const SAVE_INQUIRY_FORM = "SAVE_INQUIRY_FORM";
export const ENOTARY_MEETING_CREATED = "ENOTARY_MEETING_CREATED";
export const ENOTARY_GO_TO_HOME = "ENOTARY_GO_TO_HOME";
export const ENOTARY_JOIN_MEETING = "ENOTARY_JOIN_MEETING";
export const SEND_OTP = "SEND_OTP";
export const VERIFY_OTP = "VERIFY_OTP";
export const VERIFICATION_RESULT = "VERIFICATION_RESULT";
export const RESET_VERIFICATION_RESULT = "RESET_VERIFICATION_RESULT";
export const ENOTARY_MEETING_JOINED = "ENOTARY_MEETING_JOINED";
export const ENOTARY_CAN_ACCESS = "ENOTARY_CAN_ACCESS";
export const ENOTARY_MEETING_LEFT = "ENOTARY_MEETING_LEFT";
