import { ENOTARY_CAN_ACCESS, ENOTARY_CREATE_MEETING, ENOTARY_GO_TO_HOME, ENOTARY_JOIN_MEETING, ENOTARY_MEETING_CREATED, ENOTARY_MEETING_LEFT, RESET_VERIFICATION_RESULT, SAVE_INQUIRY_FORM, SEND_OTP, VERIFICATION_RESULT, VERIFY_OTP } from "./enotaryTypes"

export const canAccessENotary = (data) => {
    return {
        type: ENOTARY_CAN_ACCESS,
        payload: data
    }
}

export const createEnotaryMeeting = (data) => {
    return {
        type: ENOTARY_CREATE_MEETING,
        payload: data
    }
}

export const saveInquiryData = (data) => {
    return {
        type: SAVE_INQUIRY_FORM,
        payload: data
    }
}

export const enotaryMeetingLeft = (data) => {
    return {
        type: ENOTARY_MEETING_LEFT,
        payload: data
    }
}

export const afterEnotaryMeetingCreate = (data) => {
    return {
        type: ENOTARY_MEETING_CREATED,
        payload: data
    }
}

export const afterEnotaryMeetingJoin = (data) => {
    return {
        type: ENOTARY_MEETING_CREATED,
        payload: data
    }
}

export const enotaryGoToHome = (data) => {
    return {
        type: ENOTARY_GO_TO_HOME,
        payload: data
    }
}

export const joinEnotaryMeeting = (data) => {
    return {
        type: ENOTARY_JOIN_MEETING,
        payload: data
    }
}

export const setOtp = (data) => {
    return {
        type: SEND_OTP,
        payload: data
    }
}

export const verifyOtp = (data) => {
    return {
        type: VERIFY_OTP,
        payload: data
    }
}

export const verificationResult = (data) => {
    return {
        type: VERIFICATION_RESULT,
        payload: data
    }
}

export const resetVerificationResult = (data) => {
    return {
        type: RESET_VERIFICATION_RESULT,
        payload: data
    }
}